import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  CardActionArea,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const InfoCard = ({
  icon,
  label,
  children,
  isLoading,
  href,
  raised,
  elevation,
  typographyVariant,
  ...rest
}) => {
  return (
    <Card style={{ minWidth: '100%' }} elevation={elevation} raised={raised}>
      {href ? (
        <CardActionArea component={Link} to={href}>
          <CardContent {...rest}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={9}>
                <Typography variant="caption" color="textSecondary">
                  {label}
                </Typography>
                <Typography variant={typographyVariant} color="primary">
                  {isLoading ? (
                    <Skeleton animation="wave" width={70} />
                  ) : (
                    children
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="textSecondary" align="right">
                  {icon}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      ) : (
        <CardContent {...rest}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={9}>
              <Box display="block">
                <Typography variant="caption" color="textSecondary">
                  {label}
                </Typography>
              </Box>
              <Typography variant={typographyVariant} color="primary">
                {isLoading ? (
                  <Skeleton animation="wave" width={70} />
                ) : (
                  children
                )}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color="textSecondary" align="right">
                {icon}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};
InfoCard.defaultProps = {
  icon: '',
  label: '',
  children: '',
  href: '',
  raised: true,
  elevation: 3,
  typographyVariant: 'h6',
};

InfoCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]),
  label: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
  ]),
  isLoading: PropTypes.bool.isRequired,
  href: PropTypes.string,
  typographyVariant: PropTypes.string,
  raised: PropTypes.bool,
  elevation: PropTypes.number,
};

export default InfoCard;
