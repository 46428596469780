import api from '../../utils/apiFetch';
import serialize from '../../utils/serialize';

const path = 'catalog/businesses';

export default {
  getCards: (limit) => api.get(`${path}/?limit=${limit}`),
  loadMore: (fetchMoreQty, lastCardId, typeId, categoryId) =>
    api.get(
      `${path}/?limit=${fetchMoreQty}&type=${typeId}&category=${categoryId}&after=${lastCardId}`
    ),
  filterByType: (limit, typeId) =>
    api.get(`${path}/?limit=${limit}&type=${typeId}`),
  filterByCategory: (limit, typeId, categoryId) =>
    api.get(`${path}/?limit=${limit}&type=${typeId}&category=${categoryId}`),
  issue: (payload) => api.post(`gift-cards/issue`, payload),
  calculateBulkIssue: (params) =>
    api.get(`gift-cards/total-amount/${serialize(params)}`, params),
};
