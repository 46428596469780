import React from 'react';
import PropTypes from 'prop-types';
import { withTrans } from '../i18n/withTrans';

const Layout = ({ children }) => {
  return <>{children}</>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withTrans(Layout);
