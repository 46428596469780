/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';

const name = 'alert';

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      open: false,
      severity: 'info',
      message: '',
      modal: false,
    },
    reducers: {
      open: (state, { payload }) => ({
        open: true,
        severity: payload.severity,
        message: payload.message,
      }),
      close: (state) => ({
        ...state,
        open: false,
      }),
      clear: (state) => ({
        ...state,
        severity: 'info',
        message: '',
      }),
      setModal: (state, { payload }) => ({
        ...state,
        modal: payload,
      }),
    },
  }),
};

const selectors = {
  selectOpen: (state) => getOr(false, 'open', state[name]),
  selectModal: (state) => getOr(false, 'modal', state[name]),
  selectMessage: (state) => getOr('', 'message', state[name]),
  selectSeverity: (state) => getOr('info', 'severity', state[name]),
};

export default {
  actions: {
    ...actions,
  },
  selectors,
  reducer,
  name,
};
