import { getOr } from 'lodash/fp';
import { auth } from '../state';

const baseUrl = `${process.env.GATSBY_API_URL}`;

let store = {};

export const setStore = (data) => {
  store = data;
};
const fetchWithTimeout = (url, requestOptions, timeout) =>
  new Promise((resolve, reject) => {
    const controller = new AbortController();
    const timer = setTimeout(() => {
      controller.abort();
      reject(new Error('The fetch request timed out'));
    }, timeout);

    fetch(url, { ...requestOptions, signal: controller.signal })
      .then((response) => {
        clearTimeout(timer);
        resolve(response);
      })
      .catch((error) => {
        clearTimeout(timer);
        if (error.name === 'AbortError') {
          reject(new Error('The fetch request was aborted due to timeout'));
        } else {
          reject(error);
        }
      });
  });

const authHeader = () => {
  const state = store.getState();
  const user = getOr(false, ['auth', 'user'], state);
  // return auth header with jwt if user is logged in
  const token = getOr(null, 'jwtToken', user);
  const isLoggedIn = !!(user && token);
  if (isLoggedIn) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

function handleResponse(response) {
  const state = store.getState();
  const user = getOr(false, ['auth', 'user'], state);

  return response.text().then(async (text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403].includes(response.status) && user) {
        // const res = await store.dispatch(auth.actions.refreshToken());
        // if (!res.payload) {
        store.dispatch(auth.actions.setLogout());
        // }
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

const get = (url) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then(handleResponse);
};

const post = (url, body = {}, timeout = 30000) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    credentials: 'include',
    body: JSON.stringify(body),
  };
  return fetchWithTimeout(
    `${baseUrl}${url}`,
    requestOptions,
    timeout
  ).then((res) => handleResponse(res));
};

const file = (url, body = {}) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    credentials: 'include',
    body,
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then((res) =>
    handleResponse(res)
  );
};

const put = (url, body) => {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(`${baseUrl}${url}`, requestOptions).then(handleResponse);
};

const fetchWrapper = {
  get,
  post,
  put,
  file,
};

export default fetchWrapper;
