import { createTheme } from '@material-ui/core';

const theme = createTheme({
  spacing: 8,
  palette: {
    type: 'light',
    primary: {
      light: '#fff', // ripple effect color
      main: '#000', // main color
      dark: '#808080', // hoover color
      contrastText: '#fff', // text color
    },
    secondary: {
      light: '#ee6c4d', // ripple effect color
      main: '#ee6c4d', // main color
      dark: '#ee6c4d', // hoover color
      contrastText: '#fff', // text color
    },
    background: {
      default: '#fff',
    },
  },
  actions: {
    // disabled: 'rgba(0, 0, 0, 0.26)',
    // disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
  },
  typography: {
    fontFamily: 'Rubik',
  },
});

theme.overrides = {
  ...theme.overrides,
  // MuiButton: {
  //   contained: {
  //     '&$disabled': {
  //       backgroundColor: 'rgba(238,108,77, 0.6)',
  //       color: 'rgba(255,255,255, 0.6)',
  //     },
  //   },
  // },
  MuiAppBar: {
    colorPrimary: {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  MuiInputBase: {
    root: {
      fontSize: '14px',
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: '14px',
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: 'translate(14px, 17px) scale(1)',
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: '15px 14px',
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      '&&[class*="MuiOutlinedInput-root"] $input': {
        padding: '6.5px 4px',
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: '4px',
      fontSize: 12,
    },
    outlined: {
      color: '#616161',
    },
  },
  MuiToggleButton: {
    root: {
      color: theme.palette.primary.main,
      '&$selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
};

export default theme;
