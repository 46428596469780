import auth from './auth';
import alert from './alert';
import stats from './stats';
import cards from './cards';
import orgs from './orgs';
import types from './types';
import categories from './categories';
import checkout from './checkout';
import businesses from './businesses';
import enterprise from './enterprise';

export default {
  auth: auth.reducer,
  stats: stats.reducer,
  alert: alert.reducer,
  cards: cards.reducer,
  orgs: orgs.reducer,
  categories: categories.reducer,
  types: types.reducer,
  checkout: checkout.reducer,
  businesses: businesses.reducer,
  enterprise: enterprise.reducer,
};
