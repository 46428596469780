import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'stats';

const getStats = createAsyncThunk('auth/getStats', async (payload) => {
  const response = await api.getStats(payload);
  return response;
});

const getOrgStats = createAsyncThunk(
  'auth/getOrgStats',
  async (payload, { getState }) => {
    const { user } = getState().auth;
    const response = await api.getOrgStats(user);
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      stats: {},
      orgStats: [],
    },
    extraReducers: {
      [getStats.pending]: (state) => ({
        ...state,
        isLoading: true,
        stats: { ...state.stats },
      }),
      [getStats.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        isLoading: false,
        stats: getOr({}, 'payload', action),
      }),
      [getStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
      [getOrgStats.pending]: (state) => ({
        ...state,
        isLoading: true,
        getOrgStats: { ...state.stats },
      }),
      [getOrgStats.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        isLoading: false,
        orgStats: getOr([], 'payload', action),
      }),
      [getOrgStats.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectStats: (state) => getOr({}, 'stats', state[name]),
  selectOrgStats: (state) => getOr([], 'orgStats', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    getStats,
    getOrgStats,
  },
  selectors,
  reducer,
  name,
};
