import api from '../../utils/apiFetch';

export default {
  stripe: (payload) => api.post(`checkout/secret`, payload),
  clover: (payload) => api.post(`checkout/clover`, payload),
  square: (payload) => api.post(`checkout/square`, payload),
  bulk: (payload) => api.post(`checkout/bulk`, payload, 150000),
  getCloverIframeToken: (id) =>
    api.get(`checkout/clover-token?businessId=${id}`),
  totals: ({ id, businessId, giftCardConfigId, amount, isFeeCovered }) =>
    api.get(
      `checkout/totalamount?id=${
        giftCardConfigId || id || businessId
      }&amount=${amount}&isFeeCovered=${isFeeCovered}`
    ),
};
