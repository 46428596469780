import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr, isEmpty } from 'lodash/fp';
import api from './api';

const name = 'cards';
const limit = 13;
const fetchMoreQty = 9;

const getCards = createAsyncThunk('cards/getCards', async () => {
  const response = await api.getCards(limit);
  return response;
});

const calculateBulkIssue = createAsyncThunk(
  'cards/calculateBulkIssue',
  async (params) => {
    return api.calculateBulkIssue(params);
  }
);

const loadMore = createAsyncThunk(
  'cards/loadMore',
  async (payload, { getState }) => {
    const { lastCardId } = getState().cards;
    const { selectedType, selectedCategory } = payload;
    const response = await api.loadMore(
      fetchMoreQty,
      lastCardId,
      selectedType,
      selectedCategory
    );

    return response;
  }
);

const filterByType = createAsyncThunk('cards/filterByType', async (typeId) => {
  const response = await api.filterByType(limit, typeId);

  return response;
});

const filterByCategory = createAsyncThunk(
  'cards/filterByCategory',
  async ({ typeId, categoryId }) => {
    const response = await api.filterByCategory(limit, typeId, categoryId);

    return response;
  }
);

const preloadData = [{ id: '1' }];

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      isLoadingMore: false,
      isAll: false,
      lastCardId: '',
      selectedCard: {},
      error: {},
      cards: preloadData,
      configs: [],
      selectorsConfig: {},
    },
    reducers: {
      setSelectedCard: (state, action) => {
        return {
          ...state,
          selectedCard: action.payload,
        };
      },
      emptySelectedCard: (state) => {
        return {
          ...state,
          selectedCard: {},
        };
      },
      setCards: (state, action) => {
        const payload = getOr([], 'payload', action);
        return {
          ...state,
          cards: payload,
        };
      },
    },
    extraReducers: {
      [getCards.pending]: (state) => ({
        ...state,
        cards: preloadData,
        isLoading: true,
      }),
      [getCards.fulfilled]: (state, action) => {
        const last = getOr([], 'payload', action)[limit - 1];
        const displayCards = getOr([], 'payload', action).slice(0, limit - 1);
        return {
          ...state,
          errors: {},
          isLoading: false,
          cards: displayCards,
          selectedCard: {},
          lastCardId: getOr('', 'id', last),
          isAll: !isEmpty(last),
        };
      },
      [getCards.rejected]: (state, payload) => ({
        ...state,
        lastCardId: {},
        isLoading: false,
        error: payload,
      }),

      // Fetch/Load more
      [loadMore.pending.type]: (state) => ({
        ...state,
        isLoadingMore: true,
        error: {},
      }),
      [loadMore.fulfilled]: (state, action) => {
        const last = getOr([], 'payload', action)[fetchMoreQty - 1];
        const newCards = getOr([], 'payload', action).slice(
          0,
          fetchMoreQty - 1
        );
        return {
          ...state,
          isLoadingMore: false,
          lastCardId: getOr('', 'id', last),
          cards: [...state.cards, ...newCards],
          isAll: !isEmpty(last),
        };
      },
      [loadMore.rejected.type]: (state, action) => ({
        ...state,
        isLoadingMore: false,
        lastCardId: {},
        error: action.payload,
      }),

      // Filter by type

      [filterByType.pending.type]: (state) => ({
        ...state,
        cards: preloadData,
        isLoading: true,
      }),
      [filterByType.fulfilled]: (state, action) => {
        const last = getOr([], 'payload', action)[limit - 1];
        const displayData = getOr([], 'payload', action).slice(0, limit - 1);

        return {
          ...state,
          isLoading: false,
          cards: displayData,
          isAll: !isEmpty(last),
        };
      },
      [filterByType.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),

      // Filter by category

      [filterByCategory.pending.type]: (state) => ({
        ...state,
        isLoading: true,
        cards: preloadData,
      }),
      [filterByCategory.fulfilled]: (state, action) => {
        const last = getOr([], 'payload', action)[limit - 1];
        const displayData = getOr([], 'payload', action).slice(0, limit - 1);

        return {
          ...state,
          isLoading: false,
          cards: displayData,
          isAll: !isEmpty(last),
        };
      },
      [filterByCategory.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectCards: (state) => getOr({}, 'cards', state[name]),
  selectLastCardId: (state) => getOr({}, 'lastCardId', state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
  selectIsLoadingMore: (state) => getOr(false, 'isLoadingMore', state[name]),
  selectIsAll: (state) => getOr(false, 'isAll', state[name]),
  selectSelectedCard: (state) => getOr({}, 'selectedCard', state[name]),
};

export default {
  actions: {
    ...actions,
    getCards,
    loadMore,
    filterByType,
    filterByCategory,
    calculateBulkIssue,
  },
  selectors,
  reducer,
  name,
};
