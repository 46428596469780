import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'auth';

let refreshTokenTimeout;

const startRefreshTokenTimer = createAsyncThunk(
  'auth/startTokenRefresh',
  async (payload, { dispatch }) => {
    const token = getOr('', 'jwtToken', payload);
    if (token) {
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(token && atob(token.split('.')[1]));
      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - 60 * 1000;

      refreshTokenTimeout = setTimeout(
        // eslint-disable-next-line no-use-before-define
        () => dispatch(refreshToken(payload)),
        timeout
      );
    }
  }
);

const stopRefreshTokenTimer = createAsyncThunk(
  'auth/stopTokenRefresh',
  async () => {
    clearTimeout(refreshTokenTimeout);
  }
);

const login = createAsyncThunk('auth/login', async (payload, { dispatch }) => {
  const response = await api.login(payload).then((res) => {
    dispatch(startRefreshTokenTimer(res));
    return res;
  });
  return response;
});

const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (payload, { dispatch }) => {
    const response = await api.refreshToken().then(async (res) => {
      dispatch(startRefreshTokenTimer(res));
      return res;
    });
    return response;
  }
);
const logout = createAsyncThunk(
  'auth/logout',
  async (payload, { getState, dispatch }) => {
    const { user } = getState().auth;
    const response = await api.logout(user);
    dispatch(stopRefreshTokenTimer());
    return response;
  }
);

const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (payload) => {
    const response = await api.forgotPassword(payload);
    return response;
  }
);

const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (payload) => {
    const response = await api.resetPassword(payload);
    return response;
  }
);

const resendRegistrationCode = createAsyncThunk(
  'auth/register',
  async (payload) => {
    const response = await api.resendCode(payload);
    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: {},
      user: {},
      isLoggedIn: false,
    },
    reducers: {
      setIsLoggedIn: (state, action) => ({
        ...state,
        isLoggedIn: action.payload,
      }),
      setUser: (state, action) => ({
        ...state,
        user: action.payload,
      }),
      updateUser: (state, action) => ({
        ...state,
        user: { ...state.user, ...action.payload },
      }),
      setLogout: (state) => ({
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: {},
      }),
    },
    extraReducers: {
      [login.pending]: (state) => ({
        ...state,
        isLoading: true,
        user: {},
      }),
      [login.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        isLoading: false,
        user: getOr({}, 'payload', action),
        isLoggedIn: !!getOr(false, ['payload', 'jwtToken'], action),
      }),
      [login.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: payload,
      }),
      // logout
      [logout.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [logout.fulfilled]: (state) => ({
        ...state,
        error: {},
        isLoading: false,
        isLoggedIn: false,
        user: {},
      }),
      [logout.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: payload,
      }),
      // Token Refresh
      [refreshToken.pending]: (state) => ({
        ...state,
        isLoading: true,
        user: {},
      }),
      [refreshToken.fulfilled]: (state, action) => ({
        ...state,
        error: {},
        isLoading: false,
        isLoggedIn: !!getOr(false, ['payload', 'jwtToken'], action),
        user: getOr({}, 'payload', action),
      }),
      [refreshToken.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: payload,
      }),
      // Resend Password Reset Code
      [forgotPassword.pending]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [forgotPassword.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
      }),
      [forgotPassword.rejected]: (state, payload) => ({
        ...state,
        isLoading: false,
        error: payload,
      }),
    },
  }),
};

const selectors = {
  selectUser: (state) => getOr({}, 'user', state[name]),
  selectIsLoggedIn: (state) => getOr(false, 'isLoggedIn', state[name]),
  selectToken: (state) => getOr('', ['data', 'jwtToken'], state[name]),
  selectIsLoading: (state) => getOr(false, 'isLoading', state[name]),
};

export default {
  actions: {
    ...actions,
    login,
    logout,
    refreshToken,
    forgotPassword,
    resetPassword,
    resendRegistrationCode,
    stopRefreshTokenTimer,
    startRefreshTokenTimer,
  },
  selectors,
  reducer,
  name,
};
