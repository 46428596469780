import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

const PreLoader = () => {
  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: 'calc(100vh - 64px)' }}
    >
      <CircularProgress />
    </Grid>
  );
};

export default PreLoader;
