/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'checkout';

const getSecret = createAsyncThunk('checkout/secret', async (payload) => {
  const response = await api.stripe({ data: payload });

  return response;
});

const getCloverIframeToken = createAsyncThunk(
  'checkout/getCloverIframeToken',
  async (id) => {
    return api.getCloverIframeToken(id);
  }
);

const squareCheckout = createAsyncThunk(
  'checkout/square',
  async (data, { rejectWithValue }) => {
    try {
      return await api.square(data);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const bulkCheckout = createAsyncThunk(
  'checkout/bulk',
  async (data, { rejectWithValue }) => {
    try {
      return await api.bulk(data, 30000);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const cloverCheckout = createAsyncThunk(
  'checkout/clover',
  async (payload, { rejectWithValue }) => {
    try {
      return await api.clover(payload);
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const getTotals = createAsyncThunk('checkout/getTotals', async (payload) => {
  return api.totals(payload);
});

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      isCheckout: false,
      error: false,
      formData: {},
      secret: '',
      orderNumber: '',
      step: 0,
    },
    reducers: {
      setFormData: (state, { payload }) => {
        state.formData = { ...state.formData, ...payload };
      },
      resetFormData: (state) => {
        state.formData = {};
      },
      setStep: (state, { payload }) => {
        state.step = payload;
      },
    },
    extraReducers: {
      // Fetch organization

      [getSecret.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getSecret.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        secret: action.payload.secret,
        orderNumber: action.payload.orderNumber,
      }),
      [getSecret.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [squareCheckout.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [squareCheckout.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        orderNumber: action.payload.orderNumber,
      }),
      [squareCheckout.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [cloverCheckout.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [cloverCheckout.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        orderNumber: action.payload.orderNumber,
      }),
      [cloverCheckout.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
      [bulkCheckout.pending.type]: (state) => ({
        ...state,
        isLoading: true,
        isCheckout: false,
      }),
      [bulkCheckout.fulfilled]: (state) => ({
        ...state,
        isLoading: false,
        isCheckout: true,
      }),
      [bulkCheckout.rejected.type]: (state) => ({
        ...state,
        isLoading: false,
        isCheckout: false,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr('', 'isLoading', state[name]),
  selectStep: (state) => getOr(0, 'step', state[name]),
  selectFormData: (state) =>
    getOr({ currency: 'USD' }, 'formData', state[name]),
  selectOrderNumber: (state) => getOr('', 'orderNumber', state[name]),
};

export default {
  actions: {
    ...actions,
    getSecret,
    squareCheckout,
    getTotals,
    cloverCheckout,
    getCloverIframeToken,
    bulkCheckout,
  },
  selectors,
  reducer,
  name,
};
