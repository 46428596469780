/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getOr } from 'lodash/fp';
import api from './api';

const name = 'enterprise';

const getInfo = createAsyncThunk('enterprise/getInfo', async () => {
  return api.info();
});

const getGiftCards = createAsyncThunk(
  'enterprise/getGiftCards',
  async (params) => {
    return api.getGiftCards(params);
  }
);

const getTransactions = createAsyncThunk(
  'enterprise/getTransactions',
  async (params) => {
    return api.getTransactions(params);
  }
);
const getBulkTransactions = createAsyncThunk(
  'enterprise/getBulkTransactions',
  async (params) => {
    return api.getBulkTransactions(params);
  }
);

const setEnterpriseImage = createAsyncThunk(
  'businesses/setEnterPriseImage',
  async (payload) => {
    const response = await api.setEnterpriseImage(payload);

    return response;
  }
);

const updateEnterpriseImage = createAsyncThunk(
  'businesses/updateEnterpriseImage',
  async (payload) => {
    const response = await api.updateEnterpriseImage(payload);

    return response;
  }
);

const { actions, reducer } = {
  ...createSlice({
    name,
    initialState: {
      isLoading: false,
      error: false,
      info: {},
    },
    reducers: {},
    extraReducers: {
      [getInfo.pending.type]: (state) => ({
        ...state,
        isLoading: true,
      }),
      [getInfo.fulfilled]: (state, action) => ({
        ...state,
        isLoading: false,
        info: action.payload,
      }),
      [getInfo.rejected.type]: (state, action) => ({
        ...state,
        isLoading: false,
        error: action.payload,
      }),
    },
  }),
};

const selectors = {
  selectIsLoading: (state) => getOr('', 'isLoading', state[name]),
  selectInfo: (state) => getOr('', 'info', state[name]),
  selectGiftCardConfigs: (state) =>
    getOr('', 'info.giftCardConfigs', state[name]),
};

export default {
  actions: {
    ...actions,
    getInfo,
    getGiftCards,
    getTransactions,
    getBulkTransactions,
    setEnterpriseImage,
    updateEnterpriseImage,
  },
  selectors,
  reducer,
  name,
};
