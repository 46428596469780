import i18n from 'i18next';
// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en-US/translation.json';
import fr from '../locales/fr-CA/translation.json';

i18n
  // .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'fr-CA',
    fallbackLng: 'en-US',
    resources: {
      'en-US': {
        translation: en,
      },
      'fr-CA': {
        translation: fr,
      },
    },
    ns: ['translation'],
    defaultNS: 'translation',
    returnObjects: true,
    debug: true,
    react: {
      wait: true,
      useSuspense: true,
    },
  });

i18n.languages = ['en-US', 'fr-CA'];

export default i18n;
