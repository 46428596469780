import api from '../../utils/apiFetch';

const path = 'accounts';

export default {
  login: (payload) => api.post(`${path}/authenticate`, payload),
  refreshToken: (user) => api.post(`${path}/refresh-token`, { user }),
  logout: (user) => api.post(`${path}/revoke-token`, { user }),
  forgotPassword: (payload) => api.post(`auth/forgot-password`, payload),
  resetPassword: (payload) => api.post(`auth/reset-password`, payload),
};
